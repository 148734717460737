import React from 'react'
import './testimonials.css'
import AV1 from '../../assets/av1.webp'
import AV2 from '../../assets/av2.webp'
import AV3 from '../../assets/av3.webp'
import AV4 from '../../assets/av4.webp'

import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

const data = [{
  avatar: AV1,
  name: 'Dinesh Kafle',
  review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus dicta quibusdam provident. Officiis, vel nostrum? Cupiditate voluptatibus vitae est, blanditiis dicta quaerat asperiores exercitationem deleniti, ipsa laboriosam minima officiis iure.'
},
{
  avatar: AV2,
  name: 'Bimal Subedi',
  review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus dicta quibusdam provident. Officiis, vel nostrum? Cupiditate voluptatibus vitae est, blanditiis dicta quaerat asperiores exercitationem deleniti, ipsa laboriosam minima officiis iure.'
},
{
  avatar: AV3,
  name: 'Mishan Parajuli',
  review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus dicta quibusdam provident. Officiis, vel nostrum? Cupiditate voluptatibus vitae est, blanditiis dicta quaerat asperiores exercitationem deleniti, ipsa laboriosam minima officiis iure.'
},
{
  avatar: AV4,
  name: 'Manoj Pandey',
  review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus dicta quibusdam provident. Officiis, vel nostrum? Cupiditate voluptatibus vitae est, blanditiis dicta quaerat asperiores exercitationem deleniti, ipsa laboriosam minima officiis iure.'
},
]

const testimonials = () => {
  return (
    <section id="testimonials">
      <h5>Review From Clients</h5>
      <h2>Testiminials</h2>

      <Swiper className="container testimonials-contaienr"
        // install Swiper modules
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}>
        {
          data.map(({ avatar, name, review }, index) => {
            return (
              <SwiperSlide key={index} className="testimonial">
                <div className="client-avatar">
                  <img src={avatar} alt="Reviewer one" />
                </div>
                <h5 className='client-name'>{name}</h5>
                <small className="client-review">{review}</small>
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </section>
  )
}

export default testimonials
