import React from 'react'
import './experience.css'
import { MdVerified } from "react-icons/md";

const experience = () => {
  return (
    <section id="experience">
      <h5>What Skills I have</h5>
      <h2>My Experience</h2>
      <div className="container experience-container">
        {/* FRONTEND CARDs CONTENTS  */}
        <div className="experience-frontend">
          <h3>Frontend Development</h3>
          <div className="experience-content">
            <article className="experience-details">
              <MdVerified className = "experience-details-icon" />
              <div>
                <h4>HTML</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className="experience-details">
              <MdVerified className = "experience-details-icon" />
              <div>
                <h4>CSS</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className="experience-details">
              <MdVerified className = "experience-details-icon" />
              <div>
                <h4>Javascript</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>

            <article className="experience-details">
              <MdVerified className = "experience-details-icon" />
              <div>
                <h4>Bootstraps</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className="experience-details">
              <MdVerified className = "experience-details-icon" />
              <div>
                <h4>Tailwind</h4>
                <small className='text-light'>Beginner</small>
              </div>
            </article>

            <article className="experience-details">
              <MdVerified className = "experience-details-icon" />
              <div>
                <h4>ReactJS</h4>
                <small className='text-light'>Beginner</small>
              </div>
            </article>

          </div>
        </div>
        {/* BACKEND CARDS CONTENTs  */}
        <div className="experience-backend">
          <h3>Backend Development</h3>
          <div className="experience-content">
            <article className="experience-details">
              <MdVerified className = "experience-details-icon" />
              <div>
              <h4>NodeJS</h4>
              <small className='text-light'>Very Beginner</small>
              </div>
            </article>

            <article className="experience-details">
              <MdVerified className = "experience-details-icon" />
              <div>
              <h4>MongoDB</h4>
              <small className='text-light'>Not Started</small>
              </div>
            </article>

            <article className="experience-details">
              <MdVerified className = "experience-details-icon" />
              <div>
              <h4>PHP</h4>
              <small className='text-light'>Not Started</small>
              </div>
            </article>

            <article className="experience-details">
              <MdVerified className = "experience-details-icon" />
              <div>
              <h4>MySQL</h4>
              <small className='text-light'>Very Beginner</small>
              </div>
            </article>

            <article className="experience-details">
              <MdVerified className = "experience-details-icon" />
              <div>
              <h4>Python</h4>
              <small className='text-light'>Not Started</small>
              </div>
            </article>

          </div>
        </div>
      </div>
    </section>
  )
}

export default experience