import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/me.png'
import HeaderSocial from './headerSocial'

const header = () => {
  return (
   <header>
    <div className="container header-container">
      <h5>Hello I'm</h5>
      <h1>Binod Subedi</h1>
      <h5 className="text-light">
        Frontend Developer
      </h5>
      <CTA />
      <HeaderSocial />
      <div className="me">
        <img src={ME} alt="me" />
      </div>

      <a href="#contact" className='scroll-down'>Scroll Down</a>

    </div>
   </header>
  )
}

export default header
