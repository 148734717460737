import React from 'react'
import './nav.css'
import { RiHome4Line } from "react-icons/ri";
import { LuUser2 } from "react-icons/lu";
import { MdOutlineLibraryBooks } from "react-icons/md";
import { RiServiceLine } from "react-icons/ri";
import { BiMessageSquareDots } from "react-icons/bi";
import { useState } from 'react';

const Nav = () => {
  const [activeNav, setActiveNav] = useState('#')
  return (
    <nav>
      <a href="#" onClick={()=>setActiveNav('#')} className={activeNav === '#' ? 'active' : ''}><RiHome4Line /></a>
      <a href="#about" onClick={()=>setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''}><LuUser2 /></a>
      <a href="#experience"onClick={()=>setActiveNav('#experience')} className={activeNav === '#experience' ? 'active' : ''}><MdOutlineLibraryBooks /></a>
      <a href="#services"onClick={()=>setActiveNav('#services')} className={activeNav === '#services' ? 'active' : ''}><RiServiceLine /></a>
      <a href="#contact"onClick={()=>setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}><BiMessageSquareDots /></a>
    </nav>
  )
}

export default Nav
