import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/Project1.jpg'
import IMG2 from '../../assets/Project2.jpg'
import IMG3 from '../../assets/Project3.jpg'
import IMG4 from '../../assets/Project4.jpg'
import IMG5 from '../../assets/Project5.jpg'
import IMG6 from '../../assets/Project6.jpg'


const data = [{
  id: 1,
  image: IMG1,
  title: 'Personal Portfolio Website',
  github: 'https://github.com/BinodSubedi178/portfolio01',
  demo: 'https://www.subedibinod.com.np'
},
{
  id: 2,
  image: IMG2,
  title: 'Responsive Online Store',
  github: 'https://github.com/BinodSubedi178/reFlat',
  demo: 'https://binodsubedi178.github.io/reFlat/'
},
{
  id: 3,
  image: IMG3,
  title: 'Interactive To-Do WebApp',
  github: 'https://github.com/BinodSubedi178/todo',
  demo: 'https://binodsubedi178.github.io/todo/'
},
{
  id: 4,
  image: IMG4,
  title: 'Password Generator WebApp',
  github: 'https://github.com/BinodSubedi178/password_generator',
  demo: 'https://binodsubedi178.github.io/password_generator/'
},
{
  id: 5,
  image: IMG5,
  title: 'Quiz Game WebApp',
  github: 'https://github.com/BinodSubedi178',
  demo: 'https://binodsubedi178.github.io/quiz_game/'
},
{
  id: 6,
  image: IMG6,
  title: 'Weater WebApp',
  github: 'https://github.com/BinodSubedi178',
  demo: 'https://binodsubedi178.github.io/weather/'
}
]

const portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Works</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio-container">
        {
          data.map(({ id, image, title, github, demo }) => {
            return (
              <article className="portfolio-item">
                <div className="portfolio-item-image">
                  <img src={image} alt="" />
                </div>
                <h3>{title}</h3>
                <div className="portfolio-item-cta">
                  <a href={github} className='btn' target='_blank'>Github</a>
                  <a href={demo}  className='btn btn-primary' target='_blank'>Live Demo</a>
                </div>
              </article>
            )
          })
        }


      </div>
    </section>
  )
}

export default portfolio
