import React from 'react'
import { BsLinkedin } from "react-icons/bs";
import { FaGithub } from "react-icons/fa6";
import { SiReplit } from "react-icons/si";

const headerSocial = () => {
  return (
    <div className='header-socials'>
        <a href="https://www.linkedin.com/in/binod-subedi-9702a4261/" target="_blank"><BsLinkedin /></a>
        <a href="https://github.com/BinodSubedi178" target="_blank"><FaGithub /></a>
        <a href="https://replit.com/@BinodSubedi187" target="_blank"><SiReplit /></a>
      
    </div>
  )
}

export default headerSocial
