import React from 'react'
import './contact.css'
import { HiOutlineMail } from "react-icons/hi";
import { FaInstagram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { useRef } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    
    emailjs.sendForm('service_mhg0mlp', 'template_qwm091m', form.current, 'If--Ma2NadLA5Yehv')
    e.target.reset();
  }
  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>
      <div className="container contact-container">
        <div className="contact-options">
          <article className="contact-option">
          <HiOutlineMail className='contact-option-icon'/>
            <h4>Email</h4>
            <h5>binodsubedi187@gmail.com</h5>
            <a href="mailto:binodsubedi187@gmail.com" target="_blank">Send a message</a>
          </article>
          <article className="contact-option">
          <FaInstagram className='contact-option-icon'/>
            <h4>Instagram</h4>
            <h5>binod_subedi18</h5>
            <a href="https://www.instagram.com/binod_subedi18/" target="_blank">Send a message</a>
          </article>
          <article className="contact-option">
          <FaWhatsapp className='contact-option-icon'/>
            <h4>Whats App</h4>
            <h5>+61406129020</h5>
            <a href="https://wa.me/+61406129020" target="_blank">Send a message</a>
          </article>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your Full Name' required />
          <input type="email" name='email' placeholder='Your Email' required />
          <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
          <button className="btn btn-primary">Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact
