import React from 'react'
import './about.css'
import ME from '../../assets/me-about.jpeg'
import { FaAward } from "react-icons/fa";
import { TbUsers } from "react-icons/tb";
import { MdOutlineFolder } from "react-icons/md";

const about = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className="container about-container">
        <div className="about-me">
          <div className="about-me-image">
            <img src={ME} alt="Image of myself on about page" />
          </div>
        </div>
        <div className="about-content">
          <div className="about-cards">
          <article className="about-card">
          <FaAward className='about-icon' />
            <h5>Experience</h5>
            <small>0 Year Experience </small>
          </article>

          <article className="about-card">
          <TbUsers className='about-icon' />
            <h5>Clients</h5>
            <small>Self Projects Only</small>
          </article>

          <article className="about-card">
          <MdOutlineFolder className='about-icon' />
            <h5>Projects</h5>
            <small>5+ Completed Projects</small>
          </article>
          </div>
        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consequatur eum, adipisci, facere delectus libero earum fuga nulla perferendis repudiandae illum blanditiis harum ad sed. Sint alias et culpa molestias odio!</p>
        <a href="#contact"className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default about
